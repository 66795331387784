import React from 'react';
import Banner from '../shared/Banner';
import Back from '../shared/Back';

// 'any' because can't import RouteComponentProps
export default function InformationPage(props: any) {
  return (
    <div className="container">
      <Banner title="Further information" />
      <section>
        <Back previous={props.location.state} />
        <h2>Further information</h2>
          <ul className="no-list">
            <li>Staffordshire Women’s Aid<br /><a href="https://www.staffordshirewomensaid.org/" title="Staffordshire Women’s Aid (opens in a new window)" target="_blank" className="text-truncate">www.staffordshirewomensaid.org</a></li>
            <li>The Survivors Trust (Rape and Sexual Violence Services)<br /><a href="https://www.thesurvivorstrust.org/" title="The Survivors Trust (Rape and Sexual Violence Services) (opens in a new window)" target="_blank" className="text-truncate">www.thesurvivorstrust.org</a></li>
            <li>Sarac (Sexual abuse and rape advice centre)<br /><a href="https://sarac.org.uk/" title="Sarac (Sexual abuse and rape advice centre) (opens in a new window)" target="_blank" className="text-truncate">sarac.org.uk</a></li>
            <li>Staffordshire Police, Fire and Crime Commissioner<br /><a href="http://www.staffordshire-pfcc.gov.uk/initiatives/" title="Staffordshire Police, Fire and Crime Commissioner (opens in a new window)" target="_blank" className="text-truncate">www.staffordshire-pfcc.gov.uk/initiatives</a></li>
            <li>Attitude is Everything (for disability and access advice and guidance)<br /><a href="http://www.attitudeiseverything.org.uk/" title="Attitude is Everything (for disability and access advice and guidance) (opens in a new window)" target="_blank" className="text-truncate">www.attitudeiseverything.org.uk</a></li>
            <li>Design and Access (for disability audits and general buildings)<br /><a href="https://www.designandaccess.co.uk/" title="Design and Access (for disability audits and general buildings) (opens in a new window)" target="_blank" className="text-truncate">www.designandaccess.co.uk</a></li>
            <li>Good Night Out Campaign (training and licensed premises accreditation)<br /><a href="http://www.goodnightoutcampaign.org/" title="Good Night Out Campaign (training and licensed premises accreditation) (opens in a new window)" target="_blank" className="text-truncate">www.goodnightoutcampaign.org</a></li>
            <li>End Violence Against Women (for specialist women’s support services)<br /><a href="https://www.endviolenceagainstwomen.org.uk/" title="End Violence Against Women (for specialist women’s support services) (opens in a new window)" target="_blank" className="text-truncate">www.endviolenceagainstwomen.org.uk</a></li>
            <li>Police Crime Prevention Initiatives<br /><a href="https://www.policecpi.com" title="Police Crime Prevention Initiatives (opens in a new window)" target="_blank" className="text-truncate">www.policecpi.com</a></li>
            <li>Partnership against Business Crime in Staffordshire and Stoke-on-Trent <br /><a href="https://www.pabcis.co.uk" title="Partnership against Business Crime in Staffordshire and Stoke-on-Trent  (opens in a new window)" target="_blank" className="text-truncate">www.pabcis.co.uk</a></li>
            <li>Suzy Lamplugh Trust (Campaigning, education and support focusing on stalking)<br /><a href="https://www.suzylamplugh.org/" title="Suzy Lamplugh Trust (Campaigning, education and support focusing on stalking) (opens in a new window)" target="_blank" className="text-truncate">www.suzylamplugh.org</a></li>
            <li>Licensing Savi (security and vulnerability initiative for licensed venues)<br /><a href="https://www.licensingsavi.com/" title="Licensing Savi (security and vulnerability initiative for licensed venues) (opens in a new window)" target="_blank" className="text-truncate">www.licensingsavi.com</a></li>
            <li>StreetSafe - to anonymously report public places where you have felt or feel unsafe, because of environmental issues, e.g. street lighting, abandoned buildings or vandalism and/or because of some behaviours, e.g. being followed or verbally abused.<br /><a href="https://www.police.uk/pu/notices/streetsafe/street-safe/" title="StreetSafe (opens in a new window)" target="_blank" className="text-truncate">www.police.uk/pu/notices/streetsafe/street-safe/</a></li>
            <li>Staffordshire and Stoke-on-Trent Violence Reduction Alliance<br /><a href="https://www.violencereductionalliance.co.uk" title="Staffordshire and Stoke-on-Trent Violence Reduction Alliance (opens in a new window)" target="_blank" className="text-truncate">www.violencereductionalliance.co.uk</a></li>
            <li>Mayor of London - #Haveaword<br /><a href="https://youtu.be/qbk3iJqmjNU" title="Mayor of London - #Haveaword (opens in a new window)" target="_blank" className="text-truncate">https://youtu.be/qbk3iJqmjNU</a></li>
            <li>Police Scotland – Don’t be that guy<br /><a href="https://www.youtube.com/watch?v=wf-rOrOXoRI" title="Police Scotland – Don’t be that guy (opens in a new window)" target="_blank" className="text-truncate">https://www.youtube.com/watch?v=wf-rOrOXoRI</a></li>
            <li>Sussex Police – Do the right thing <br /><a href="https://www.facebook.com/watch/?v=642113260253363" title="Sussex Police – Do the right thing (opens in a new window)" target="_blank" className="text-truncate">https://www.facebook.com/watch/?v=642113260253363</a></li>
            <li>Home Office – Enough is Enough: Be part of the change <br /><a href="https://www.youtube.com/watch?v=_f-SY0EOzrk" title="Home Office – Enough is Enough: Be part of the change (opens in a new window)" target="_blank" className="text-truncate">https://www.youtube.com/watch?v=_f-SY0EOzrk</a></li>
            <li>Zero Tolerance<br /><a href="https://www.zerotolerance.org.uk/" title="Zero Tolerance  (opens in a new window)" target="_blank" className="text-truncate">https://www.zerotolerance.org.uk</a></li>
          </ul>
      </section>
    </div>
  );
}