import React from 'react';
import AuthControls from '../auth/AuthControls';

export default function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer-image">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-4">
            <h5>Safer Places for Women and Girls Charter Toolkit</h5>
            <AuthControls location="footer" />
            <p>Copies of this Charter are available at:<br />
            <a href="https://www.violencereductionalliance.co.uk" target="_blank" title="Visit www.violencereductionalliance.co.uk (opens in a new window)">www.violencereductionalliance.co.uk</a></p>
          </div>
          <div className="col-12 col-sm-4">
            <h5>Violence Reduction Alliance</h5>
            <address>
              C/O PFCC<br />
              Staffordshire Police HQ<br />
              Weston Road<br />
              Stafford<br />
              ST18 0Y<br />
            </address>
            <p><a href="https://www.violencereductionalliance.co.uk" target="_blank" title="Visit www.violencereductionalliance.co.uk (opens in a new window)">www.violencereductionalliance.co.uk</a></p>
            <p>Enquiries:<br /><a href="mailto:ssvra@staffordshire-pfcc.gov.uk" target="_blank" title="Contact ssvra@staffordshire-pfcc.gov.uk (opens in a new window)">ssvra@staffordshire-pfcc.gov.uk</a></p>
          </div>
          <div className="col-12 col-sm-4">
            <p className="d-flex"><span>Powered by</span><img src="/images/tillr-powered.svg" alt="Powered by Tillr" /></p>
            <p className="mb-0"><a href="mailto:hello@tillr.io" target="_blank" title="Contact tillr.io (opens in a new window)" className="nav-link p-0">Contact</a></p>
            <p><a href="https://tillr.io" target="_blank" title="Visit tillr.io (opens in a new window)" className="nav-link p-0">tillr.io</a></p>
          </div>
        </div>
        <div className="d-flex justify-content-between py-4 my-4 border-top">
          <p>&copy; {year}</p>
        </div>
      </div>
    </footer>
  );
}