import React, { PropsWithChildren } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function ButtonLink(props: PropsWithChildren<{ to: string, className: string, }>) {
  const { children, to, className } = props;
  const history = useHistory();
  const { pathname } = useLocation();
  const handleClick = () => {
    history.push(to, { from: pathname });
  };

  return (
    <button className={className} onClick={handleClick}>{children}</button>
  );
}