import React from 'react';
import ButtonLink from '../shared/ButtonLink';

export default function Nav() {
  return (
    <nav className="nav">
      <ul>
        <li><ButtonLink className="btn btn-large" to="/introduction"><span>Introduction</span><span></span></ButtonLink></li>
        <li><ButtonLink className="btn btn-large" to="/charter-commitments"><span>Charter Commitments</span><span></span></ButtonLink></li>
        <li><ButtonLink className="btn btn-large" to="/actioning-the-checklist"><span>Actioning the Commitments</span><span></span></ButtonLink></li>
        <li><ButtonLink className="btn btn-large" to="/charter-checklist"><span>Charter Checklist</span><span></span></ButtonLink></li>
        <li><ButtonLink className="btn btn-large" to="/other-initiatives"><span>Other Initiatives</span><span></span></ButtonLink></li>
        <li><ButtonLink className="btn btn-large" to="/information"><span>Further information</span><span></span></ButtonLink></li>
      </ul>
    </nav>
  );
}