import React from 'react';
import ButtonLink from './ButtonLink';

export default function Back(props: { previous: { from: string | undefined } }) {
  const { previous } = props;
  const from = previous && previous.from ? previous.from : null;
  return (
    <div className="btn-fabs">
      {from && (
      <ButtonLink to={from} className="btn-fab"><i className="bi-chevron-left"></i></ButtonLink>
      )}
      <ButtonLink to="/contents" className="btn-fab btn-dark"><i className="bi-house"></i></ButtonLink>
    </div>
  );
}