import React from 'react';
import Banner from '../shared/Banner';
import Nav from './Nav';

export default function ContentsPage() {
  return (
    <div className="container">
      <div className="home-container">
        <Banner />
        <Nav />
      </div>
    </div>
  );
}