function getUrlPrefix(): string {
  if (window.location.hostname.startsWith('local')) {
    // e.g. localhost:3000, localspwgcharter.tillr.io
    return 'local';
  }
  if (window.location.hostname.startsWith('uat')) {
    return 'uat';
  }
  if (window.location.hostname.startsWith('demo')) {
    return 'demo';
  }
  return '';
}

interface IOidcConfig {
  oidcProvider: string;
  clientId: string;
  authRedirect: string;
  silentRedirect: string;
}

export function getOidcConfig(): IOidcConfig {
  const urlPrefix = getUrlPrefix();
  let clientDomain;
  let clientId;

  if (urlPrefix === 'local') {
    clientDomain = 'http://localhost:3000';
    clientId = 'spwgcharter.tillr.client';
  } else {
    const { hostname } = window.location;
    const subdomain = hostname.substring(urlPrefix.length, hostname.indexOf('.'));
    clientDomain = `https://${urlPrefix}${subdomain}.tillr.io`;
    clientId = `${subdomain}.tillr.client`;
  }

  return {
    oidcProvider: `https://${urlPrefix}accounts.tillr.io/core/`,
    clientId,
    authRedirect: `${clientDomain}/authenticated/`,
    silentRedirect: `${clientDomain}/authenticated/silent-renew`,
  };
}