import * as qwest from 'qwest';
import clsx from 'clsx';
import React, { useState, useContext } from 'react';
import Banner from '../shared/Banner';
import Back from '../shared/Back';
import UserManagerContext from '../../misc/UserManagerContext';
import { isValidEmail, getNewUUID, getUrlPrefix } from '../../misc/utils';
import { Link } from 'react-router-dom';

// 'any' because can't import RouteComponentProps
export default function RegisterPage(props: any) {
  const userManager = useContext(UserManagerContext)!;
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [confEmail, setConfEmail] = useState<string>('');
  const [organisation, setOrganisation] = useState<string>('');
  const [organisationType, setOrganisationType] = useState<string>('');
  const [primaryCheckbox, setPrimaryCheckbox] = useState<boolean>(true);
  const [parentCheckbox, setParentCheckbox] = useState<boolean>(true);

  const [emailError, setEmailError] = useState<boolean>(false);
  const [confEmailError, setConfEmailError] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [emailExists, setEmailExists] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handleSignIn = () => {
    setLoading(true);
    userManager.signinRedirect();
  };

  const doSubmit = () => {
    setLoading(true);
    const url = `https://${getUrlPrefix()}accounts.tillr.io/api/SignUps`;

    const cData = {
      organisation,
      organisationType,
      marketing: {
        'primary': primaryCheckbox,
        'parent': parentCheckbox,
      },
    };

    const data = {
      ClientId: 'spwgcharter.tillr.client',
      Email: email,
      UserId: getNewUUID(),
      CustomData: JSON.stringify(cData),
    };

    qwest.post(url, data)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setHasSubmitted(true);
      })
      .catch((e, xhr, response) => {
        setLoading(false);
        if (response.Message && response.Message.match(/exists/)) {
          setEmailExists(true);
        } else {
          setError(true);
        }
      });
  };

  const handleSubmit = () => {
    setError(false);
    setEmailError(false);
    setEmailExists(false);
    setSuccess(false);
    setHasSubmitted(false);

    // Validate email
    setEmailError(!isValidEmail(email));
    // Validate confirmed email
    setConfEmailError(email !== confEmail);
    // Complete validation
    setValidated(true);
    if (
      emailError ||
      confEmailError ||
      !organisation.length ||
      !organisationType.length
    ) {
      return;
    }
    doSubmit();
  };

  const orgTypes: string[] = [
    'Licensed premises (e.g pub, bar or nightclub)',
    'Late night refreshment (e.g. restaurant, takeaway, etc)',
    'Cultural venue (e.g. theatre, music venue, museum)',
    'Leisure (e.g. sports and fitness club)',
    'Local authority',
    'Accomodation',
    'Police and emergency services',
    'Transport',
    'Business Improvement Districts',
    'Trade Association',
    'Other',
  ];

  return (
    <div className="container">
      <Banner title="Register" />
      <section>
        <Back previous={props.location.state} />
        {(!hasSubmitted || (hasSubmitted && !success)) && (
        <p><strong>To gain access to the toolkit, please register below. Fields marked with an asterisk (<span className="warning">*</span>) are required.</strong></p>
        )}
        {hasSubmitted && success && (
          <>
            <p><strong>Thank you, Your registration has been sent.</strong></p>
            <p><strong>You will shortly receive an email with your activation link.</strong></p>
          </>
        )}
        {error && (
          <p className="warning"><strong>Sorry, we couldn't register you. Please try again later.</strong></p>
        )}
        {emailExists && (
          <p className="warning"><strong>That email address is already registered. Please use an alternate email address or <Link to="#" onClick={handleSignIn}>sign in</Link>.</strong></p>
        )}
        {!hasSubmitted && (
        <form noValidate>
          <fieldset>
            <div className="col-8 form-row">
              <label
                htmlFor="email-input"
                className="form-label"
              >
                Email address <span className="warning">*</span>
              </label>
              <input
                required
                type="email"
                autoComplete="off"
                className={clsx([
                  'form-control',
                  'form-control-lg',
                  validated && emailError ? 'form-error-input' : '',
                ])}
                id="confirm-email-input"
                aria-describedby="confirm-email-input"
                value={email}
                maxLength={255}
                onChange={(event) => setEmail(event.target.value)}
              />
              {validated && emailError && (
              <div className="email-error d-print-none form-error">
                Please enter a valid email address.
              </div>
              )}
            </div>
            <div className="col-8 form-row">
              <label
                htmlFor="confirm-email-input"
                className="form-label"
              >
                Confirm your email address <span className="warning">*</span>
              </label>
              <input
                required
                type="email"
                autoComplete="off"
                className={clsx([
                  'form-control',
                  'form-control-lg',
                  validated && confEmailError ? 'form-error-input' : '',
                ])}
                id="confirm-email-input"
                aria-describedby="confirm-email-input"
                value={confEmail}
                maxLength={255}
                onChange={(event) => setConfEmail(event.target.value)}
              />
              {validated && confEmailError && (
              <div className="confirm-email-error d-print-none form-error">
                Emails don't match.
              </div>
              )}
            </div>
            <div className="col-8 form-row">
              <label
                htmlFor="organisation-input"
                className="form-label"
              >
                Organisation name <span className="warning">*</span>
              </label>
              <input
                required
                type="text"
                autoComplete="off"
                className={clsx([
                  'form-control',
                  'form-control-lg',
                  validated && !organisation.length ? 'form-error-input' : '',
                ])}
                id="organisation-input"
                aria-describedby="organisation-input"
                value={organisation}
                maxLength={255}
                onChange={(event) => setOrganisation(event.target.value)}
              />
              {validated && !organisation.length && (
              <div className="organisation-error d-print-none form-error">
                This field is required.
              </div>
              )}
            </div>
            <div className="col-8 form-row">
              <label
                htmlFor="organisation-type-input"
                className="form-label"
              >
                Organisation type <span className="warning">*</span>
              </label>
              <select
                required
                className={clsx([
                  'form-control',
                  'form-control-lg',
                  validated && !organisationType.length ? 'form-error-input' : '',
                ])}
                id="organisation-type-input"
                aria-describedby="organisation-type-input"
                value={organisationType}
                onChange={(event) => setOrganisationType(event.target.value)}
              >
                <option value="">Please select</option>
                {orgTypes.map((type) => (
                  <option
                    key={type}
                    value={type}
                  >
                    {type}
                  </option>
                ))}
              </select>
              {validated && !organisationType.length && (
              <div className="organisation-type-error d-print-none form-error">
                This field is required.
              </div>
              )}
            </div>
            <div className="col-8 form-row form-check-row">
              <div className="form-check">
                <input className="form-check-input" checked={primaryCheckbox} onChange={(event) => setPrimaryCheckbox(event.target.checked)} type="checkbox" id="primary-checkbox" />
                <label className="form-check-label" htmlFor="primary-checkbox">
                  Yes! I’d like to receive marketing communications from Staffordshire and Stoke-on-Trent Violence Reduction Alliance (Safer Places for Women and Girls Charter).
                </label>
              </div>
            </div>
            <div className="col-8 form-row form-row-check">
              <div className="form-check">
                <input className="form-check-input" checked={parentCheckbox} onChange={(event) => setParentCheckbox(event.target.checked)} type="checkbox" id="parent-checkbox" />
                <label className="form-check-label" htmlFor="parent-checkbox">
                  Yes! I’d like to receive marketing communications from Staffordshire Police, Fire and Crime Commissioners Office, our parent organisation.
                </label>
              </div>
            </div>
            <button type="button" className="btn btn-primary btn-lg" aria-label="Register" onClick={handleSubmit} disabled={loading}>
              Register
              {loading && <span className="spinner-border" role="status" aria-hidden="true"></span>}
            </button>
          </fieldset>
        </form>
        // Success
        )}
      </section>
    </div>
  );
}