import React from 'react';
import { User, UserManager } from 'oidc-client';
import 'bootstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './components/shared/Layout';
import './styles/index.scss';
import createUserManager from './misc/createUserManager';
import UserManagerContext from './misc/UserManagerContext';
import UserProfile from './misc/UserProfile'
;import UserProfileContext from './misc/UserProfileContext';

interface IState {
  loaded: boolean;
  userProfile: UserProfile | null;
}

export default class App extends React.Component<{}, IState> {
  private readonly userManager: UserManager;

  constructor(props: {}) {
    super(props);

    this.state = { loaded: false, userProfile: null };
    this.userManager = createUserManager(this.handleUserChanged);
  }

  public componentDidMount() {
    this.userManager.getUser()
      .then((user) => {
        const userProfile = user ? new UserProfile(user) : null;
        this.setState({ loaded: true, userProfile });
      });
  }

  private handleUserChanged = (user: User | null) => {
    if (!user) {
      this.setState({ userProfile: null });
    } else {
      this.setState((prevState) => {
        const userProfile = new UserProfile(user);
        return ({ ...prevState, userProfile });
      });
    }
  };

  public render() {
    const { loaded, userProfile } = this.state;
    if (!loaded) {
      return <p>Loading...</p>;
    }

    return (
      <UserManagerContext.Provider value={this.userManager}>
        <UserProfileContext.Provider value={userProfile}>
          <div className="App">
            <Router>
              <Layout />
            </Router>
          </div>
        </UserProfileContext.Provider>
      </UserManagerContext.Provider>
    );
  }
}