import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router';
import UserProfileContext from '../../misc/UserProfileContext';

export default function AuthenticatedRoute(props: any) {
  const userProfile = useContext(UserProfileContext);

  const { permissions, ...routeProps } = props;
  if (userProfile) {
    return <Route {...routeProps} />;
  }

  return <Redirect to="/" />;
}
