import clsx from 'clsx';
import React, { useEffect, useState, useContext } from 'react';
import Banner from '../shared/Banner';
import Back from '../shared/Back';
import { IChecklistServerObject, IChecklistAllItems, IChecklistModel } from '../../misc/IChecklist';
import { getChecklist, updateChecklist } from '../../misc/checklist';
import ChecklistItem from '../shared/ChecklistItem';
import { scrollToSmoothly } from '../../misc/utils';
import UserProfileContext from '../../misc/UserProfileContext';

// 'any' because can't import RouteComponentProps
export default function ActionPage(props: any) {
  const userProfile = useContext(UserProfileContext)!;
  const [previous, setPrevious] = useState(props.location.state);
  const [activeSection, setActiveSection] = useState<string>();
  const [checklist, setChecklist] = useState<IChecklistAllItems | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const [model, setModel] = useState<IChecklistModel>({});
  const [instanceId, setInstanceId] = useState<string | null>(null);

  useEffect(() => {
    getChecklist(userProfile)
      .then((data: IChecklistServerObject | void) => {
        setChecklist(data && data.fields || null);
        setInstanceId(data?.id || null);
        setModel(data?.model || {});
        setLoading(false);
      })
      .catch((e: any) => {
        // TODO
        setLoading(false);
        console.error(`ERROR: ${e.message}`);
      });

    const hash = window.location.hash;
    if (hash) {
      const id = hash.substring(1, hash.length);
      setActiveSection(id);
      const item = document.getElementById(`item-${id}`);
      if (item) {
        const pos = item.offsetTop;
        scrollToSmoothly(pos + 100, 0);
      }
      setPrevious({ from: '/change' });
    }
  },
  []);

  const onValueUpdate = (fieldKey: string, value: any) => {
    const newModel = {
      ...model,
      [fieldKey]: value,
    };

    updateChecklist(userProfile, instanceId, newModel)
      .then((formId: string | void) => {
        if (formId && formId.length) {
          setInstanceId(formId);
        }
        setModel(newModel);
      })
      .catch((e: any) => {
        // TODO
        console.error(`ERROR: ${e.message}`);
      });
  };

  return (
    <div className="container">
      <Banner title="Putting the commitments into action" />
      <section>
        <Back previous={previous} />
        <h2>Putting the commitments into action</h2>
        <div className="accordion" id="commitments-accordion">
          <div id="item-champion" className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className={clsx('accordion-button', activeSection !== 'champion' && 'collapsed')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded={ activeSection === 'champion' } aria-controls="collapseOne">
                <span>1</span>
                <span>Champion</span>
              </button>
            </h2>
            <div id="collapseOne" className={clsx('accordion-collapse', 'collapse', activeSection === 'champion' && 'show')} aria-labelledby="headingOne">
              <div className="accordion-body">
                {/* <!-- 1 Champion -->
                <!-- 1 Champion : START --> */}
                {loading && (
                  <div className="skeleton-wrapper">
                    <table className="checklist-items">
                      <caption>Checklist</caption>
                      <thead>
                        <tr>
                          <th className="checklist-item-status" scope="col"></th>
                          <th className="checklist-item-name" scope="col">Checklist</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="skeleton"></div>
                  </div>
                )}
                {!loading && (
                <table className="checklist-items">
                  <caption>Checklist</caption>
                  <thead>
                    <tr>
                      <th className="checklist-item-status" scope="col"></th>
                      <th className="checklist-item-name" scope="col">Checklist</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checklist && checklist['0'].fields.map((field) => (
                    <ChecklistItem
                      key={field.key}
                      field={field}
                      onValueUpdate={onValueUpdate}
                      modelValue={model[field.key]}
                    />
                  ))}
                  </tbody>
                </table>
                )}
                <p>A champion is someone who makes a difference because they:</p>
                <ul>
                  <li>Are the point of contact for all work undertaken on this issue;</li>
                  <li>Ensure the Charter commitments are met by the organisation/business;</li>
                  <li>Keep all staff updated on the Charter and its implementation;</li>
                  <li>Commit to helping all staff understand that women's safety and vulnerability is a shared responsibility;</li>
                  <li>Give the time and resources to advocate for practical and cultural change;</li>
                  <li>Engage with colleagues positively when taking action to improve the safety of women and girls, and encourage others to do so; </li>
                  <li>Empower colleagues to challenge unsafe language and behaviours;</li>
                  <li>Support the embedding of relevant policies and procedures.</li>
                </ul>
                <p>The Champion will also be the main point of contact for the Charter and will have access to the on-line Toolkit - SPWGCharter.tillr.io , which provides potential best practice to help achieve the commitments of the Charter.</p>
                {/* <!-- 1 Champion : END --> */}
              </div>
            </div>
          </div>
          <div id="item-communicate"  className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className={clsx('accordion-button', activeSection !== 'communicate' && 'collapsed')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded={ activeSection === 'communicate' } aria-controls="collapseTwo">
                <span>2</span>
                <span>Communicate</span>
              </button>
            </h2>
            <div id="collapseTwo" className={clsx('accordion-collapse', 'collapse', activeSection === 'communicate' && 'show')} aria-labelledby="headingTwo">
              <div className="accordion-body">
                {/* <!-- 2 Communicate -->
                <!-- 2 Communicate : START --> */}
                {loading && (
                  <div className="skeleton-wrapper">
                    <table className="checklist-items">
                      <caption>Checklist</caption>
                      <thead>
                        <tr>
                          <th className="checklist-item-status" scope="col"></th>
                          <th className="checklist-item-name" scope="col">Checklist</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="skeleton"></div>
                  </div>
                )}
                {!loading && (
                <table className="checklist-items">
                  <caption>Checklist</caption>
                  <thead>
                    <tr>
                      <th className="checklist-item-status" scope="col"></th>
                      <th className="checklist-item-name" scope="col">Checklist</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checklist && checklist['1'].fields.map((field) => (
                    <ChecklistItem
                      key={field.key}
                      field={field}
                      onValueUpdate={onValueUpdate}
                      modelValue={model[field.key]}
                    />
                  ))}
                  </tbody>
                </table>
                )}
                <p>Displaying a poster or notice about your organisation's values in relation to women's safety and the consequences for unacceptable behaviour can have a hugely positive impact. In doing so, you are naming something that many organisations have traditionally refused to acknowledge.</p>
                <p>Many women and girls are reassured to know what the values of that space are and who and where they can go to for support.</p>
                <p>Communication campaigns let staff and customers know that you take their safety seriously and creates an environment where everyone feels confident to report incidents and perpetrators know that unacceptable behaviour will not be tolerated.</p>
                <p>A range of local campaigns have been developed to support the prevention of violence against women and girls, these include:</p>
                <h3>Make No Excuses</h3>
                <p>A campaign to highlight behaviour and encourage change, provide a daily insight into those who experience harassment, have experienced unsafe environments, empower people to make change and help others without putting themselves at risk.</p>
                <h3>Unite Against Harassment</h3>
                <p>An empowerment campaign aiming to support, advise and help to educate against harassment in everyday situations including work, home, social and leisure spaces.</p>
                <h3>Safer Nights – including Signs of Spiking</h3>
                <p>A campaign to support, advise and help to educate everyone to have a great night out in Staffordshire and Stoke-on-Trent.  </p>
                <p>The campaign resources, including posters, key messages and social media content can be found at:<br />
                <a href="https://www.violencereductionalliance.co.uk" target="_blank" title="Visit www.violencereductionalliance.co.uk (opens in a new window)">www.violencereductionalliance.co.uk</a></p>
                <h3>National Campaigns</h3>
                <p>A number of national campaigns have been developed to support this work and resources can be found in the ‘Further Information’ section of this Charter.</p>
                {/* <!-- 2 Communicate : END --> */}
              </div>
            </div>
          </div>
          <div id="item-support-staff" className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button className={clsx('accordion-button', activeSection !== 'support-staff' && 'collapsed')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded={ activeSection === 'support-staff' } aria-controls="collapseThree">
                <span>3</span>
                <span>Support Staff</span>
              </button>
            </h2>
            <div id="collapseThree" className={clsx('accordion-collapse', 'collapse', activeSection === 'support-staff' && 'show')} aria-labelledby="headingThree">
              <div className="accordion-body">
                {/* <!-- 3 Support your Staff -->
                <!-- 3 Support your staff : START --> */}
                {loading && (
                  <div className="skeleton-wrapper">
                    <table className="checklist-items">
                      <caption>Checklist</caption>
                      <thead>
                        <tr>
                          <th className="checklist-item-status" scope="col"></th>
                          <th className="checklist-item-name" scope="col">Checklist</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="skeleton"></div>
                  </div>
                )}
                {!loading && (
                <table className="checklist-items">
                  <caption>Checklist</caption>
                  <thead>
                    <tr>
                      <th className="checklist-item-status" scope="col"></th>
                      <th className="checklist-item-name" scope="col">Checklist</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checklist && checklist['2'].fields.map((field) => (
                    <ChecklistItem
                      key={field.key}
                      field={field}
                      onValueUpdate={onValueUpdate}
                      modelValue={model[field.key]}
                    />
                  ))}
                  </tbody>
                </table>
                )}
                <p>Unwelcome sexual jokes, comments, staring, wolf whistling and inappropriate touching are all forms of harassment.</p>
                <p>Sexual harassment, sexual assault and hate crimes more generally target identity and can be very personal. Victims often blame themselves, remaining silent about what happened.  Reports are often not made due to fear of being blamed, of being disbelieved or being further victimised. </p>
                <p>Workplaces should address these barriers with a combination of cultural and practical changes;</p>
                <ul>
                  <li>Communicate the company policy to all staff and online;</li>
                  <li>Make sure all employees and management understand what all forms of harassment are;</li>
                  <li>Reporting is confidential and taken seriously;</li>
                  <li>Staff and management meetings regularly discuss and plan ways safety of women and girls could be prioritised;</li>
                  <li>Invest in staff training (incl. harassment, vulnerability etc.);</li>
                  <li>Ensure a harassment free workplace.</li>
                </ul>
                <h3>Example Policies/Guidance</h3>
                <p>The Health and Safety Executive have produced guidance and an example policy to reduce work-based violence towards staff:</p>
                <p><a href="https://www.hse.gov.uk/violence/toolkit/policies.htm" target="_blank" title="Visit https://www.hse.gov.uk/violence/toolkit/policies.htm (opens in a new window)">https://www.hse.gov.uk/violence/toolkit/policies.htm</a></p>
                <p><a href="https://www.hse.gov.uk/violence/toolkit/examplepolicy.pdf" target="_blank" title="View https://www.hse.gov.uk/violence/toolkit/examplepolicy.pdf (opens in a new window)">https://www.hse.gov.uk/violence/toolkit/examplepolicy.pdf</a></p>
                <p>These can be adapted to include employee related violence and to cover gender-based violence.</p>
                <p><a href="https://www.template.net/business/policy/workplace-violence-prevention-policy/" target="_blank" title="Visit https://www.template.net/business/policy/workplace-violence-prevention-policy/ (opens in a new window)">https://www.template.net/business/policy/workplace-violence-prevention-policy/</a></p>
                <h3>Gender-based violence policies:</h3>
                <p>There are various template policies available related to gender-based violence and a simple web browser search will provide these examples.</p>
                {/* <!-- 3 Support your staff : END --> */}
              </div>
            </div>
          </div>
          <div id="item-support-the-public" className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button className={clsx('accordion-button', activeSection !== 'support-the-public' && 'collapsed')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded={ activeSection === 'support-the-public' } aria-controls="collapseFour">
                <span>4</span>
                <span>Support the Public</span>
              </button>
            </h2>
            <div id="collapseFour" className={clsx('accordion-collapse', 'collapse', activeSection === 'support-the-public' && 'show')}  aria-labelledby="headingFour">
              <div className="accordion-body">
                {/* <!-- 4 Support the Public -->
                <!-- 4 Support your public : START --> */}
                {loading && (
                  <div className="skeleton-wrapper">
                    <table className="checklist-items">
                      <caption>Checklist</caption>
                      <thead>
                        <tr>
                          <th className="checklist-item-status" scope="col"></th>
                          <th className="checklist-item-name" scope="col">Checklist</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="skeleton"></div>
                  </div>
                )}
                {!loading && (
                <table className="checklist-items">
                  <caption>Checklist</caption>
                  <thead>
                    <tr>
                      <th className="checklist-item-status" scope="col"></th>
                      <th className="checklist-item-name" scope="col">Checklist</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checklist && checklist['3'].fields.map((field) => (
                    <ChecklistItem
                      key={field.key}
                      field={field}
                      onValueUpdate={onValueUpdate}
                      modelValue={model[field.key]}
                    />
                  ))}
                  </tbody>
                </table>
                )}
                <p>Harassment can cause fear, alarm and distress.  The repercussions of not challenging unacceptable behaviour can negatively influence culture whilst having a knock-on effect of impacting business and local economies.</p>
                <p>It is critical that organisations / businesses have policies in place to ensure that the public can recognise unacceptable behaviour and understand how complaints will be dealt with.</p>
                <p>Policies should have a clear statement that your organisation / business is committed to providing a safe environment for the public, free from discrimination on any ground and from unacceptable behaviour including harassment. A zero-tolerance policy is operated; all incidents will be treated seriously, respectfully and confidentially, with a prompt investigation on allegations made. No one will be victimised for making a complaint.</p>
                <p>It is now more important than ever to fight the stigma and ensure victims are listened to, believed, and supported.</p>
                <h3>Examples:</h3>
                <p>The policies highlighted in Commitment 3 of this Charter – Support Staff can be inclusive to address this Commitment - Support the Public.</p>
                {/* <!-- 4 Support your public : END --> */}
              </div>
            </div>
          </div>
          <div id="item-training" className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button className={clsx('accordion-button', activeSection !== 'training' && 'collapsed')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded={ activeSection === 'training' } aria-controls="collapseFive">
                <span>5</span>
                <span>Training</span>
              </button>
            </h2>
            <div id="collapseFive" className={clsx('accordion-collapse', 'collapse', activeSection === 'training' && 'show')} aria-labelledby="headingFive">
              <div className="accordion-body">
                {/* <!-- 5 Training: responding -->
                <!-- 5 Training responding : START --> */}
                {loading && (
                  <div className="skeleton-wrapper">
                    <table className="checklist-items">
                      <caption>Checklist</caption>
                      <thead>
                        <tr>
                          <th className="checklist-item-status" scope="col"></th>
                          <th className="checklist-item-name" scope="col">Checklist</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="skeleton"></div>
                  </div>
                )}
                {!loading && (
                <table className="checklist-items">
                  <caption>Checklist</caption>
                  <thead>
                    <tr>
                      <th className="checklist-item-status" scope="col"></th>
                      <th className="checklist-item-name" scope="col">Checklist</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checklist && checklist['4'].fields.map((field) => (
                    <ChecklistItem
                      key={field.key}
                      field={field}
                      onValueUpdate={onValueUpdate}
                      modelValue={model[field.key]}
                    />
                  ))}
                  </tbody>
                </table>
                )}
                <p>Well trained staff naturally improve the overall performance of your business and can also help to create a safer environment for both colleagues and the public.</p>
                <h3>Benefits to training</h3>
                <ul>
                  <li>The organisation/business environment becomes safer;</li>
                  <li>Organisation/business culture is strengthened;</li>
                  <li>Staff will have the skills and confidence to identify vulnerability, handle difficult situations and proactively respond;</li>
                  <li>Staff and the public are better protected and supported;</li>
                  <li>Defines and develops appropriate behaviour / culture;</li>
                  <li>Creates a safe and welcoming environment the public are more likely to revisit.</li>
                </ul>
                <h3>Local Training</h3>
                <p>Re-Solv in partnership with the Violence Reduction Alliance have developed Vulnerability Awareness Training. This training can be delivered face to face by contacting <a href="mailto:SSVRA@staffordshire-pfcc.gov.uk" title="Contact SSVRA@staffordshire-pfcc.gov.uk (opens in a new window)" target="_blank">SSVRA@staffordshire-pfcc.gov.uk</a> and is also available at:</p>
                <p>A webinar version of this training can be viewed at<br />
                <a href="https://www.violencereductionalliance.co.uk" target="_blank" title="Visit www.violencereductionalliance.co.uk (opens in a new window)">www.violencereductionalliance.co.uk</a></p>
                <p>A short induction into this training can be viewed at<br />
                <a href="https://www.violencereductionalliance.co.uk" target="_blank" title="Visit www.violencereductionalliance.co.uk (opens in a new window)">www.violencereductionalliance.co.uk</a></p>
                <h3>National Training</h3>
                <p>Good Night Out Campaign (training and licensed premises accreditation) - <a href="https://www.goodnightoutcampaign.org" target="_blank" title="Visit www.goodnightoutcampaign.org (opens in a new window)">www.goodnightoutcampaign.org</a></p>
                <p>Welfare and Vulnerability Engagement – WAVE is a training session which aims to increase the skills, knowledge and confidence of those working in licensed premises focusing on identifying vulnerability and making appropriate interventions - <a href="https://www.safersounds.org.uk/wave" target="_blank" title="Visit https://www.safersounds.org.uk/wave  (opens in a new window)">https://www.safersounds.org.uk/wave</a></p>
                {/* <!-- 5 Training responding : END --> */}
              </div>
            </div>
          </div>
          <div id="item-designing-out-safety" className="accordion-item">
            <h2 className="accordion-header" id="headingSeven">
              <button className={clsx('accordion-button', activeSection !== 'designing-out-safety' && 'collapsed')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded={ activeSection === 'designing-out-safety' } aria-controls="collapseSeven">
                <span>6</span>
                <span>Designing-out Crime</span>
              </button>
            </h2>
            <div id="collapseSeven" className={clsx('accordion-collapse', 'collapse', activeSection === 'designing-out-safety' && 'show')} aria-labelledby="headingSeven">
              <div className="accordion-body">
                {/* <!-- 6 Designing-out Crime -->
                <!-- 6 Designing-out Crime : START --> */}
                {loading && (
                  <div className="skeleton-wrapper">
                    <table className="checklist-items">
                      <caption>Checklist</caption>
                      <thead>
                        <tr>
                          <th className="checklist-item-status" scope="col"></th>
                          <th className="checklist-item-name" scope="col">Checklist</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="skeleton"></div>
                  </div>
                )}
                {!loading && (
                <table className="checklist-items">
                  <caption>Checklist</caption>
                  <thead>
                    <tr>
                      <th className="checklist-item-status" scope="col"></th>
                      <th className="checklist-item-name" scope="col">Checklist</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checklist && checklist['5'].fields.map((field) => (
                    <ChecklistItem
                      key={field.key}
                      field={field}
                      onValueUpdate={onValueUpdate}
                      modelValue={model[field.key]}
                    />
                  ))}
                  </tbody>
                </table>
                )}
                <p>Daytime and night-time economy spaces should be designed to be welcoming, minimising the risk of enabling 'opportunistic' perpetrators, who may use a lack of clear visibility in a venue or outside space to their advantage. </p>
                <p>Your business / organisation should always be designed with health and safety in mind.</p>
                <p className="no-margin-bottom">Consider:</p>
                <ul>
                  <li>Premise layout;</li>
                  <li>Lighting;</li>
                  <li>CCTV;</li>
                  <li>Designated safe space;</li>
                  <li>Security.</li>
                </ul>
                <h3>Partnerships against Business Crime in Staffordshire (PABCIS)</h3>
                <p>PABCIS is a partnership, working to tackle business crime in Staffordshire and Stoke-on-Trent.  PABCIS is a membership-based organisation, which in the main is a voluntary membership.  The Partnership is focussed on preventing and reducing crime and disorder affecting retail and night time economy businesses, in town and city centres throughout Staffordshire and Stoke-on-Trent.</p>
                <p>PABCIS carries out many functions and empowers businesses by imposing sanctions on individuals who impact on our communities, and have updated their nightlife exclusions policy to support local businesses in their aim to reduce violence against women and girls.  </p>
                <p>For further information or to join PABCIS, please go to <a href="https://www.pabcis.co.uk" target="_blank" title="Visit www.pabcis.co.uk (opens in a new window)">www.pabcis.co.uk</a>.</p>
                <h3>National Resources:</h3>
                <p>Police Crime Prevention Initiatives - <a href="https://www.policecpi.com" target="_blank" title="Visit www.policecpi.com (opens in a new window)">www.policecpi.com</a>.</p>
                <p>Licensing Savi (security and vulnerability initiative for licensed venues) - <a href="https://www.licensingsavi.com" target="_blank" title="Visit www.licensingsavi.com (opens in a new window)">www.licensingsavi.com</a></p>
                {/* <!-- 7 Designing-out Crime : END --> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}