import { User } from 'oidc-client';
export default class UserProfile {
  readonly name: string;

  readonly authHeaderValue: string;

  readonly tenantName: string;

  readonly getUserId: () => string;

  constructor(private user: User) {
    const { profile } = user;

    this.name = `${profile.given_name} ${profile.family_name}`;

    this.authHeaderValue = `${user.token_type} ${user.access_token}`;

    this.tenantName = profile.tenantName;

    this.getUserId = () => profile.userId;
  }

}
