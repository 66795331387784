import React from 'react';
import AuthControls from '../auth/AuthControls';

interface IProps {
  title?: string | null;
  buttons?: boolean;
}

export default function Banner(props: IProps) {
  const { title = null, buttons = false } = props;

  return (
    <>
      <div className="banner">
        {!title ? (
        <h1><span>Safer Places for</span> <span>Women and Girls</span> <span>Charter Toolkit</span></h1>
        ) : (
        <h1><span>{title}</span></h1>
        )}
        {buttons && (
          <AuthControls />
        )}
      </div>
    </>
  );
}