/* eslint-disable @typescript-eslint/quotes */
import { IChecklistServerObject, IChecklistPost, IChecklistField, IChecklistAllItems, IChecklistModel, IChecklistItems, IChecklistItem } from './IChecklist';
import UserProfile from './UserProfile';
import { getUrlPrefix, getNewUUID } from './utils';

const getChecklistFormId = () => {
  const urlPrefix = getUrlPrefix();
  if (urlPrefix === 'local') {
    return '34cc6531-ba7d-4efa-8ab4-51f3b32a43df'; // Local
  }
  if (urlPrefix === 'uat') {
    return '34cc6531-ba7d-4efa-8ab4-51f3b32a43df'; // UAT
  }
  return '34cc6531-ba7d-4efa-8ab4-51f3b32a43df'; // Prod
};

const formatDataForChecklist = (data: IChecklistField[]) => {
  let allItems: IChecklistAllItems = {};
  // @ts-ignore
  data[0]?.fieldGroup.forEach((group: any, i: number) => {
    allItems[String(i)] = <IChecklistItems>{
      title: group.data.label,
      fields: <IChecklistItem[]>[],
    };
    group.fieldGroup.forEach((field: IChecklistField) => {
      const d = {
        key: field.key,
        label: field?.templateOptions?.label,
      };
      // @ts-ignore
      allItems[String(i)].fields = allItems[String(i)].fields.concat([d]);
    });

  });
  return allItems;
};

async function getFormInstanceByTemplateId(id: string, userId: string, config: any) {
  const instancesUrl = `https://${getUrlPrefix()}api2.tillr.io/Forms/GetByTemplateId/${id}`;
  let instances;
  try {
    instances = await fetch(instancesUrl, config);
  } catch (e) {
    throw new Error('Failed getting instances');
  }
  if (instances.status === 200) {
    return instances?.json();
  }
  throw new Error('Failed getting instances');
}

async function getFormTemplateById(id: string, config: any) {
  const templateUrl = `https://${getUrlPrefix()}api2.tillr.io/FormTemplates/GetFormTemplateById/${id}`;
  let template;
  try {
    template = await fetch(templateUrl, config);
  } catch (e) {
    throw new Error('Failed getting template');
  }
  if (template.status === 200) {
    return template?.json();
  }
  throw new Error('Failed getting template');
}

async function getChecklist(userProfile: UserProfile) {
  const templateId = getChecklistFormId();
  const config = {
    headers: {
      'Authorization': userProfile.authHeaderValue,
      'ModuleId': 7,
    },
  };

  let instances;
  let template;
  const form: IChecklistServerObject = {
    id: null,
    model: null,
  };

  try {
    [instances, template] = await Promise.all([
      getFormInstanceByTemplateId(templateId, userProfile.getUserId(), config),
      getFormTemplateById(templateId, config),
    ]);
  } catch (e: any) {
    throw new Error(e.message);
  }

  if (instances && instances.length) {
    const instance = instances[0];
    form.id = instance.Id;
    form.model = JSON.parse(instance.FormDataJson);
  }

  if (template) {
    const templateJSON = JSON.parse(template.Template);
    const items = templateJSON.fields;
    form.fields = formatDataForChecklist(items);
  }

  return form;
}

async function updateChecklist(userProfile: UserProfile, instanceId: string | null, model: IChecklistModel) {
  let baseUrl = `https://${getUrlPrefix()}api2.tillr.io/Forms/`;
  let mode = 'Update';
  let request;
  let formId = instanceId;

  if (!formId) {
    mode = 'Create';
    formId = getNewUUID();
  }

  const values: IChecklistPost = {
    Id: formId,
    TemplateId: getChecklistFormId(),
    FormDataJson: JSON.stringify(model),
    IsSubmitted: false,
    Name: 'SPWG Charter Checklist',
  };

  try {
    request = await fetch(`${baseUrl}${mode}`, {
      headers: {
        'Authorization': userProfile.authHeaderValue,
        'ModuleId': '7',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(values),
    });
  } catch (e: any) {
    throw new Error('1: Unable to save form instance');
  }

  if (request.status === 200 || request.status === 204) {
    return formId;
  }

  throw new Error('2: Unable to save form instance');
}

export { getChecklist, updateChecklist };
