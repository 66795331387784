import React from 'react';

export default function Cover() {

  return (
    <div className="cover cover-home">
      <div className="rellax" data-rellax-speed="-4">
        <div
          className="cover-inner cover-image"
        ></div>
      </div>
    </div>
  );
}