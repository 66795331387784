import React from 'react';
import Banner from '../shared/Banner';

export default function HomePage() {
  return (
    <div className="container">
      <div className="home-container">
        <Banner buttons={true} />
        <section>
          {/* <h2>Safer Places for Women and Girls Charter Toolkit</h2> */}
          <p>The Staffordshire and Stoke-on-Trent Violence Reduction Alliance (VRA) believes that harassment and violence in any form is unacceptable.  It is committed to creating safer public spaces for all.</p>
          <p>The <abbr title="Violence Reduction Alliance">VRA</abbr> encourages you to pledge your commitment to women's safety.</p>
          <p>The toolkit is filled with information and ideas as well as signposting to other initiatives and campaigns support and help.  No matter what size step you take, every step taken makes a difference.</p>
        </section>
      </div>
    </div>
  );
}