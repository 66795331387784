import React, { useState, useEffect, useContext } from 'react';
import Banner from '../shared/Banner';
import Back from '../shared/Back';
import { IChecklistServerObject, IChecklistAllItems, IChecklistModel } from '../../misc/IChecklist';
import { getChecklist, updateChecklist } from '../../misc/checklist';
import ChecklistItem from '../shared/ChecklistItem';
import UserProfileContext from '../../misc/UserProfileContext';

// 'any' because can't import RouteComponentProps
export default function ChecklistPage(props: any) {
  const userProfile = useContext(UserProfileContext)!;
  const [checklist, setChecklist] = useState<IChecklistAllItems | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const [model, setModel] = useState<IChecklistModel>({});
  const [instanceId, setInstanceId] = useState<string | null>(null);

  useEffect(() => {
    getChecklist(userProfile)
      .then((data: IChecklistServerObject | void) => {
        setChecklist(data && data.fields || null);
        setInstanceId(data?.id || null);
        setModel(data?.model || {});
        setLoading(false);
      })
      .catch((e: any) => {
        // TODO
        setLoading(false);
        console.error(`ERROR: ${e.message}`);
      });
  },
  []);

  const onValueUpdate = (fieldKey: string, value: any) => {
    const newModel = {
      ...model,
      [fieldKey]: value,
    };

    updateChecklist(userProfile, instanceId, newModel)
      .then((formId: string | void) => {
        if (formId && formId.length) {
          setInstanceId(formId);
        }
        setModel(newModel);
      })
      .catch((e: any) => {
        // TODO
        console.error(`ERROR: ${e.message}`);
      });
  };

  return (
    <div className="container">
      <Banner title="Charter Checklist" />
      <section>
        <Back previous={props.location.state} />
        <h1>Charter Checklist</h1>
        {loading && (
          <div className="skeleton-wrapper">
            <table className="checklist-items">
              <caption>Checklist</caption>
              <thead>
                <tr>
                  <th className="checklist-item-status" scope="col"></th>
                  <th className="checklist-item-name" scope="col">Checklist</th>
                </tr>
              </thead>
            </table>
            <div className="skeleton"></div>
          </div>
        )}
        {!loading && (
        <table className="checklist-items">
          <caption>Checklist</caption>
          <thead>
            <tr>
              <th className="checklist-item-status" scope="col"></th>
              <th className="checklist-item-name" scope="col">Checklist</th>
            </tr>
          </thead>
          <tbody>
            {checklist && (
              <>
                {Object.keys(checklist).map((key) => (
                <tr key={key}>
                  <td colSpan={2} className="checklist-items">
                    <table className="checklist-items">
                      <tbody>
                      {checklist[key].fields.map((field) => (
                        <ChecklistItem
                          key={field.key}
                          field={field}
                          onValueUpdate={onValueUpdate}
                          modelValue={model[field.key]}
                        />
                      ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        )}
      </section>
    </div>
  );
}