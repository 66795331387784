import { User, UserSettings } from 'oidc-client';
import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router';
import UserManagerContext from '../../misc/UserManagerContext';

export default function Authenticated() {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const userManager = useContext(UserManagerContext)!;

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  userManager.processSigninResponse()
    .then((response) => {
      // TODO: find out why expires_at isn't on Response type but it is there?
      const user = new User(response as unknown as UserSettings);
      userManager.storeUser(user);
      userManager.events.load(user);
      setAuthenticated(true);
    })
    .catch((e: Error) => {
      if (e.message === 'No matching state found in storage') {
        // TODO: find out why this happens
        // Everything still succeeds in handling the response, but this error is also thrown.
        // This doesn't happen if we remove <Route path="/:module?/*"> from App.tsx.
      } else {
        throw e;
      }
    });

  return (
    <div className="container">
      <section>
        <p>Validating auth token...</p>
      </section>
    </div>
  );
}
