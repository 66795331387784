import React from 'react';
import { Link } from 'react-router-dom';
import AccountMenu from '../auth/AccountMenu';

export default function Header() {
  return (
    <header>
      <div className="header-background"></div>
      <div className="header-top">
        <div className="container">
          <div className="navbar-partner">
            <Link to="/"><img src="/images/logo.svg" alt="Mayor of London" /></Link>
          </div>
          <div className="navbar-partner"></div>
          <div className="navbar-partner">
            <div className="d-flex justify-content-end align-items-center">
              <a href="https://tillr.io" target="_blank"><span>Powered by</span><img src="/images/tillr-powered.svg" alt="Powered by Tillr" /></a>
              <span>|</span>
              <AccountMenu />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}