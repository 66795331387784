export function getUrlPrefix(): string {
  if (window.location.hostname.startsWith('local')) {
    // TODO
    return 'uat';
  }
  if (window.location.hostname.startsWith('uat')) {
    return 'uat';
  }
  if (window.location.hostname.startsWith('demo')) {
    return 'demo';
  }
  return '';
}

export function scrollToSmoothly(position: number, duration: number): void {
  let pos = position;
  let time = duration;
  if (typeof pos !== 'number') {
    pos = parseFloat(pos);
  }
  if (isNaN(pos)) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw 'Position must be a number';
  }
  if (pos < 0 || time < 0) {
    return;
  }
  const currentPos = window.scrollY || window.screenTop;
  let start: null | number = null;
  time = time || 500;
  window.requestAnimationFrame(function step(currentTime: number) {
    start = !start ? currentTime : start;
    if (currentPos < pos) {
      let progress = currentTime - start;
      window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    } else {
      let progress = currentTime - start;
      window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    }
  });
}

export function isValidEmail(str: string) : boolean {
  // @ts-ignore
  return (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(str));
}

export function getNewUUID(): string {
  const s = [];
  const hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4';  // bits 12-15 of the time_hi_and_version field to 0010
  // @ts-ignore
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';
  return s.join('');
}
