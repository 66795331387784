import React, { useEffect, useState } from 'react';
import { IChecklistItem } from '../../misc/IChecklist';

interface IProps {
  modelValue: boolean;
  onValueUpdate: (fieldKey: string, value: any) => void;
  field: IChecklistItem;
}

export default function ChecklistItem(props: IProps) {
  const [pending, setPending] = useState<boolean>(false);
  const {
    onValueUpdate,
    modelValue,
    field,
  } = props;

  useEffect(() => {
    setPending(false);
  },
  [modelValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onValueUpdate(field.key, event.currentTarget.checked);
    setPending(true);
  };

  return (
    <tr>
      <td className="checklist-item-status">
        {!pending ? (
          <input
            className="custom-checkbox"
            onChange={handleChange}
            type="checkbox"
            checked={!!modelValue}
          />
        ) : (
          <div className="checklist-item-spinner">
            <span className="spinner-border" role="status" aria-hidden="true"></span>
          </div>
        )}
      </td>
      <td>{field.label}</td>
    </tr>
  );
}