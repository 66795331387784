import React, { useContext } from 'react';
import UserManagerContext from '../../misc/UserManagerContext';

export default function Authenticate(props: any) {
  const { location: { search } } = props;
  const loginHint = new URLSearchParams(search).get('login_hint');

  const userManager = useContext(UserManagerContext)!;
  userManager.signinRedirect({ login_hint: loginHint });

  return (
    <div className="container">
      <section>
        <p>Redirecting for token...</p>
      </section>
    </div>
  );
}
