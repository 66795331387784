import React from 'react';
import Banner from '../shared/Banner';
import Back from '../shared/Back';

// 'any' because can't import RouteComponentProps
export default function IntroPage(props: any) {
  return (
    <div className="container">
      <Banner title="Introduction" />
      <section>
        <Back previous={props.location.state} />
        <h2>Introduction</h2>
        <p><strong>Violence against Women and Girls is a national and local priority.</strong></p>
        <p>All women and girls have the right to enjoy Staffordshire and Stoke-on-Trent both during the day and at night.  However, we know that they experience challenges, meaning this isn't always possible.</p>
        <p>Women and girls still face much higher levels of sexual harassment, violence, assault, and verbal abuse coupled with lower reporting levels, especially in the case of public space harassment.  </p>
        <p>A recent local survey highlighted <strong>67%</strong> of female respondents had experienced harassment, intimidation, or violence in a public place, with <strong>87%</strong> stating they did not report it to the police.  <strong>92%</strong> feared walking down subways or alleyways, followed by <strong>90%</strong> feared walking in poorly lit areas in the night-time economy.</p>
        <p>The Staffordshire and Stoke-on-Trent multi-agency Violence Reduction Alliance (VRA) believes that harassment in any form is unacceptable.  It is committed to creating safer public spaces, particularly for women and girls, along with others who might find themselves vulnerable when using such spaces.</p>
        <p>The VRA is calling on all businesses and organisations operating in Staffordshire and Stoke-on-Trent to work together to reduce violence against women and girls in public spaces and to improve feelings of safety in the day and at night, including the night-time economy.</p>
        <hr />
        <h3>Women and Girls views and experiences in Staffordshire and Stoke-on-Trent</h3>
        <p>A local survey undertaken in June 2021, highlighted the following views and experiences of women and girls in Stoke-on-Trent and Staffordshire:</p>
        <blockquote>
          <p>"Getting told by police or government spokespeople after a woman in the area has been murdered or assaulted that 'not going out at night' is a precaution we should take is insulting. Is that really the best society can do for us? We're adult women; we're not children. We shouldn't have a 'be home by...' time."</p>
        </blockquote>
        <blockquote>
          <p>"Girls and women will only be safe when the consensus is that street harassment is
unacceptable and to be condemned by all those who see it as well as experience it."
</p>
        </blockquote>
        <blockquote>
          <p>"I am so angry that at 57 years old I am still being told it is up to me to be aware, to be careful where I go, when it is men who assault women who should be thinking about their own behaviours and its impact
on us. As long as the clear message is be afraid, don't get into trouble it is a green light for men to behave however they like towards women."
</p>
        </blockquote>
        <blockquote>
          <p>"Women should not be feeling like this in the 21st century, as an older lady I don't feel secure going out after 9pm and no one should feel like this" </p>
        </blockquote>
        <blockquote>
          <p>"Why don't I have the same freedom as my boyfriend and my father to walk in my own area without being harassed? I am an adult woman. I shouldn't have to take someone with me. I've been harassed in broad daylight at 9.00am in the morning going to work in a business suit. It's happened at workplace, at school, whilst running. It doesn't matter what you wear or what you're doing - it happens anyway."</p>
        </blockquote>
        <blockquote>
          <p>"As a young female I always feel on my guard if I am out alone, even during the day time. Even 'harmless banter' can feel intimidating, which comes down to re-educating that this is not acceptable."</p>
        </blockquote>
        <blockquote>
          <p>"It's really important that while moves are made to protect the safety of everyone in public, we acknowledge that male violence is responsible for the majority of public harm and focus on shaming and inculcating fear in THEM for their behaviour, creating change, rather than on women being forced to adapt even more."</p>
        </blockquote>
        <blockquote>
          <p>"A male friend of mine said he never had to ask himself if it was safe to go out alone but from a small child I was always warned about "strange men" and have grown up accepting that there are certain things a woman can't do, like going out alone at night. This can't be right, why do we accept this curtail of our freedom? Because that's how it's always been and a woman who ventures out on her own at night is viewed partly at fault if anything happens to her, "why was she out so late on her own" is the usual comment."</p>
        </blockquote>
        <blockquote>
          <p>"I think that more should be done in the workplace, some men of a certain generation can't see that women may feel unsafe, senior management don't take this seriously."</p>
        </blockquote>
        <blockquote>
          <p>"In the town where I work it would be alleyways and empty streets My daughter works in a pub and is frequently subjected to harassing comments and attention that is unwanted and sexual in nature"</p>
        </blockquote>
        <hr />
        <h3>Safer Places for Women and Girls Charter</h3>
        <p>The Charter is part of the VRA's commitment to tackling violence against women and girls, bringing together a range of partners and businesses to take responsibility and practical steps to make their area a safer place.</p>
        <p>The VRA encourages you and your organisation to pledge your commitment to women's safety.</p>
        <p>As part of the Safer Places for Women and Girls Charter an online interactive toolkit has been developed – SPWGCharter.tillr.io.</p>
        <p>The interactive resource will assist the Charter signatories in meeting the six commitments of the Charter as well as signpost to best practice, information, and other useful resources. This will include:</p>
        <ul>
          <li>Best practice examples from local businesses and organisations in Staffordshire and Stoke on Trent to be shared with signatories to use rather than re-creating the wheel;</li>
          <li>New and existing campaigns on a national level and as well as from the Violence Reduction Alliance;</li>
          <li>National video campaigns - <a href="https://youtu.be/qbk3iJqmjNU" target="_blank" title="Visit https://youtu.be/qbk3iJqmjNU (opens in a new window)">#Haveaword</a> and <a href="https://www.youtube.com/watch?v=wf-rOrOXoRI" target="_blank" title="Visit https://www.youtube.com/watch?v=wf-rOrOXoRI (opens in a new window)">Don't be that Guy</a> and <a href="https://enough.campaign.gov.uk/" target="_blank" title="Visit https://enough.campaign.gov.uk (opens in a new window)">Home Office enough campaign</a></li>
        </ul>
        <p>Businesses and organisations can support significant progress with their colleagues, day to day business, policies and processes as well as supporting safer places in their communities.  No matter what size step you take, every step taken makes a difference.</p>
        <hr />
        <h3>Signing the Charter – What does it mean?</h3>
        <p>The Safer Places for Women and Girls Charter is a voluntary pledge to show you and your organisation take women and girl's safety seriously. By signing the Charter*, you show your acknowledgement of the issue and that you're ready to get proactive in improving women and girl's safety and experience. We're building a strong network dedicated to making a positive change to women and girl's experiences across Staffordshire and Stoke-on-Trent.</p>
        <p><strong>This Toolkit provides ideas, inspiration, information and resources to help your organisations meet the six commitments.  Whatever your organisation, there will be something you can do to better support women and girl's safety.</strong></p>
        <br />
        <br />
        <br />
        <p>*When you sign up to the Charter, you will be provided with a poster, window decal or social media content to identify that your organisation/business is a Charter signatory</p>
      </section>
    </div>
  );
}