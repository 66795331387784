import React from 'react';
import Banner from '../shared/Banner';
import Back from '../shared/Back';

// 'any' because can't import RouteComponentProps
export default function InitiativesPage(props: any) {
  return (
    <div className="container">
      <Banner title="Other Initiatives/Campaigns" />
      <section>
        <Back previous={props.location.state} />
        <h2>Other Initiatives/Campaigns</h2>
        <div className="pull-out important">
          <h3 className="dark">Ask for Angela</h3>
          <p>The safety initiative ‘Ask for Angela’ is for people who feel unsafe, vulnerable or threatened and can discreetly seek help by approaching venue staff and asking them for ‘Angela’. This code-phrase will indicate to staff that they require help with their situation and a trained member of staff will then look to support and assist them. This might be through reuniting them with a friend, seeing them to a taxi, or by calling venue security and/or the police. </p>
          <p><a href="https://www.areyouok.co.uk/im-a-professional/campaign-materials/ask-for-angela/" target="_blank" title="Visit Ask For Angela (opens in a new tab/window)" className="text-truncate">https://www.areyouok.co.uk/im-a-professional/campaign-materials/ask-for-angela/</a>.</p>
        </div>
        <div className="pull-out important">
          <h3 className="dark">Hollie Guard</h3>
          <p>Hollie Guard is an app that can track a person’s location as they make their way home, as they’re out jogging or cycling, or simply going about their day-to-day tasks.  If they feel unsafe, all they need to do is shake their phone to send an alert to a group of contacts to warn them that they may be in danger. Once the phone has been shaken, it will then start recording both audio and visual footage of what’s happening around them.</p>
          <p>This will be saved and sent to their contacts so they can see what is happening and call the police or ambulance service if they are required.</p>
          <p>If the phone is shaken again, a flashing light and high-pitched beeping will start coming from the phone to alert people nearby that that person may be in danger. <a href="https://hollieguard.com/" target="_blank" title="Visit hollieguard.com (opens in a new tab/window)">https://hollieguard.com/</a>.</p>
        </div>
      </section>
    </div>
  );
}