import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomePage from './components/home/HomePage';
import ContentsPage from './components/contents/ContentsPage';
import IntroPage from './components/introduction/IntroPage';
import ActionPage from './components/action/ActionPage';
import ChecklistPage from './components/checklist/ChecklistPage';
import CommitmentsPage from './components/commitments/CommitmentsPage';
import InformationPage from './components/information/InformationPage';
import InitiativesPage from './components/initiatives/InitiativesPage';

import AuthenticatedRoute from './components/auth/AuthenticatedRoute';
import AuthenticatedSilentRenew from './components/auth/AuthenticatedSilentRenew';
import Authenticate from './components/auth/Authenticate';
import Authenticated from './components/auth/Authenticated';
import RegisterPage from './components/auth/RegisterPage';

export default (
  <Switch>
    <Route exact path="/" component={HomePage} />

    <AuthenticatedRoute exact path="/contents" component={ContentsPage} />
    <AuthenticatedRoute exact path="/introduction" component={IntroPage} />
    <AuthenticatedRoute exact path="/charter-commitments" component={CommitmentsPage} />
    <AuthenticatedRoute exact path="/actioning-the-checklist" component={ActionPage} />
    <AuthenticatedRoute exact path="/charter-checklist" component={ChecklistPage} />
    <AuthenticatedRoute exact path="/other-initiatives" component={InitiativesPage} />
    <AuthenticatedRoute exact path="/information" component={InformationPage} />

    <Route exact path="/register" component={RegisterPage} />
    <Route exact path="/authenticate" component={Authenticate} />
    <Route exact path="/registration" component={Authenticate} />
    <Route exact path="/authenticated" component={Authenticated} />
    <Route exact path="/authenticated/silent-renew" component={AuthenticatedSilentRenew} />
  </Switch>
);
