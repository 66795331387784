import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Banner from '../shared/Banner';
import Back from '../shared/Back';

// 'any' because can't import RouteComponentProps
export default function CommitmentsPage(props: any) {
  return (
    <div className="container">
      <Banner title="Charter Commitments" />
      <section>
        <Back previous={props.location.state} />
        <h2>Charter Commitments</h2>
        <p>We have drawn together a number of commitments to support the safety of women and girls at night.  We are asking businesses and local organisations to;</p>
        <table className="commitments">
          <caption>The Seven Commitments</caption>
          <tbody>
            <tr>
              <th scope="row">1. Champion</th>
              <td>Appoint a named person to lead the Charter commitments </td>
              <td className="td-small"><Link to="/actioning-the-checklist#champion">More info</Link></td>
            </tr>
            <tr>
              <th scope="row">2. Communicate</th>
              <td>Positive public and staff facing communication campaigns both on line and in your space</td>
              <td className="td-small"><Link to="/actioning-the-checklist#communicate">More info</Link></td>
            </tr>
            <tr>
              <th scope="row">3. Support Staff</th>
              <td>Provide a mechanism which enables your staff to report unacceptable behaviour whilst at work</td>
              <td className="td-small"><Link to="/actioning-the-checklist#support-staff">More info</Link></td>
            </tr>
            <tr>
              <th scope="row">4. Support Public</th>
              <td>Provide a mechanism which enables the public to report unacceptable behaviour whilst using your service or space</td>
              <td className="td-small"><Link to="/actioning-the-checklist#support-the-public">More info</Link></td>
            </tr>
            <tr>
              <th scope="row">5. Training</th>
              <td>Develop understanding, skills and confidence to identify vulnerability, respond, record and share information appropriately</td>
              <td className="td-small"><Link to="/actioning-the-checklist#training">More info</Link></td>
            </tr>
            <tr>
              <th scope="row">6. Designing out crime</th>
              <td>Create a safer environment to reduce the risk of crime</td>
              <td className="td-small"><Link to="/actioning-the-checklist#designing-out-crime">More info</Link></td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
}