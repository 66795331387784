import React, { useContext, useState } from 'react';
import UserManagerContext from '../../misc/UserManagerContext';
import UserProfileContext from '../../misc/UserProfileContext';

export default function AccountMenu() {
  const userProfile = useContext(UserProfileContext)!;
  const userManager = useContext(UserManagerContext)!;
  const [loading, setLoading] = useState<boolean>(false);

  const handleSignIn = () => {
    setLoading(true);
    userManager.signinRedirect();
  };

  const handleSignOut = () => {
    setLoading(true);
    userManager.signoutRedirect();
  };

  return (
    <>
      <button className="btn-fab"  id="account-menu" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi-person-circle"></i></button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
        {userProfile ? (
          <>
            <li>
              <button disabled className="dropdown-item text-truncate" onClick={handleSignOut} aria-label="Sign out">
                Signed in as:<br />
                {userProfile.name}
              </button>
            </li>
            <li><hr className="dropdown-divider" /></li>
            <li>
              <button disabled={loading} className="dropdown-item" onClick={handleSignOut} aria-label="Sign out">
                Sign out
                {loading && <span className="spinner-border" role="status" aria-hidden="true"></span>}
              </button>
            </li>
          </>
        ) : (
          <>
            <li>
              <button disabled={loading} className="dropdown-item" onClick={handleSignIn} aria-label="Sign in">
                Sign in
                {loading && <span className="spinner-border" role="status" aria-hidden="true"></span>}
              </button>
            </li>
          </>
        )}
      </ul>
    </>
  );
}