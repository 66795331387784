import React from 'react';
import { scrollToSmoothly } from '../../misc/utils';

export default function ChatPage() {
  const handleClick = () => {
    scrollToSmoothly(0, 250);
  };
  return (
    <div className="btn-fabs btn-fabs-bottom">
      <button aria-label="Back to top" onClick={handleClick} className="btn-fab"><i className="bi-chevron-up"></i></button>
      <button aria-label="Email support" className="btn-fab btn-dark"><a href="mailto:support@tillr.io" aria-label="Email support" title="Email support"><i className="bi-envelope"></i></a></button>
    </div>
  );
}