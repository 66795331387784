import React, { useContext, useState } from 'react';
import ButtonLink from '../shared/ButtonLink';
import UserManagerContext from '../../misc/UserManagerContext';
import UserProfileContext from '../../misc/UserProfileContext';
import { Link } from 'react-router-dom';

interface IProps {
  location?: string;
}

export default function AuthControls(props: IProps) {
  const { location } = props;
  const userProfile = useContext(UserProfileContext)!;
  const userManager = useContext(UserManagerContext)!;
  const [loading, setLoading] = useState<boolean>(false);

  const handleSignIn = () => {
    setLoading(true);
    userManager.signinRedirect();
  };

  if (location && location === 'footer') {
    return (
      <ul className="nav flex-column">
        {userProfile ? (
          <li className="nav-item mb-2">
            <Link to="/contents">Access the toolkit</Link>
          </li>
        ) : (
          <>
            <li className="nav-item mb-2">
              <Link to="/register">Register for access to the toolkit</Link>
            </li>
            <li className="nav-item mb-2">
              <Link to="#" onClick={handleSignIn}>
                Already registered? Sign in
                {loading && <span className="spinner-border" role="status" aria-hidden="true"></span>}
              </Link>
            </li>
          </>
        )}
      </ul>
    );
  }

  return (
    <>
      {userProfile ? (
        <ButtonLink to="/contents" className="btn btn-large">Access the toolkit</ButtonLink>
      ) : (
        <>
          <ButtonLink to="/register" className="btn btn-large">Register for access to the toolkit</ButtonLink>
          <button className="btn btn-large" aria-label="Sign in" onClick={handleSignIn} disabled={loading}>
            Already registered? Sign in
            {loading && <span className="spinner-border" role="status" aria-hidden="true"></span>}
          </button>
        </>
      )}
    </>
  );
}